import { Button } from '@mui/joy';
import {
  IconCar,
  IconFridge,
  IconPlayerPause,
  IconPlayerPlay,
  IconSofa,
} from '@tabler/icons-react';
import { useCallback, useState } from 'react';
import Group from './components/Group/Group';
import { useSpotifySdk } from './contexts/SpotifySdkProvider';
import {
  useCanControlAirplayQuery,
  useDevicesQuery,
  usePlaybackStateQuery,
} from './queries/spotify';
import { queryClient } from './queries/queryClient';

function ReloadButton({
  setIsChangingPlayback,
}: {
  setIsChangingPlayback: (value: boolean) => void;
}) {
  return (
    <Button
      onClick={() => {
        setIsChangingPlayback(false);
        queryClient.clear();
      }}
    >
      Reload
    </Button>
  );
}

type AirplayTarget = 'kueche' | 'aren' | 'wohnzimmer';

const setAirplayTarget = async (name: AirplayTarget) => {
  console.log('Setting airplay target', name);
  try {
    const response = await fetch(`https://jam.staudtc.de/?target=${name}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Failed to change airplay device');
    }
  } catch (e) {
    console.error(e);
  }
};

function ChangeAirPlayDeviceButton({
  target,
  icon,
  setTarget,
  isChangingPlayback,
}: {
  target: AirplayTarget;
  icon: React.ReactNode;
  setTarget: (target: AirplayTarget) => void;
  isChangingPlayback: boolean;
}) {
  return (
    <Button
      variant="soft"
      size="md"
      color="neutral"
      loading={isChangingPlayback}
      onClick={async () => {
        setTarget(target);
      }}
    >
      {icon}
    </Button>
  );
}

export default function PlaybackState({ children }: { children: React.ReactNode }) {
  const [isChangingPlayback, setIsChangingPlayback] = useState(false);
  const result = useDevicesQuery();
  const canControlAirplay = useCanControlAirplayQuery();
  const playbackResult = usePlaybackStateQuery();
  const sdk = useSpotifySdk();
  const device = result.data;
  const deviceId = device?.id;
  console.log('DeviceID', deviceId);
  const setAirplayDevice = useCallback(
    async (target: AirplayTarget) => {
      if (!deviceId) {
        return;
      }
      setIsChangingPlayback(true);
      try {
        await sdk.player.pausePlayback(deviceId);
      } catch (e) {
        console.log('Failed to pause playback', e);
      }
      await setAirplayTarget(target);
      const maxAttempts = 5;
      const attemptStartResumePlayback = async (attempts: number) => {
        try {
          await sdk.player.transferPlayback([deviceId], true);
          console.log('Successfully resumed');
        } catch (e) {
          console.log(`Attempt ${attempts + 1} failed:`, e);
          await new Promise((resolve) => setTimeout(resolve, 400));
          if (attempts + 1 < maxAttempts) {
            await attemptStartResumePlayback(attempts + 1);
          } else {
            console.log('Max attempts reached. Failed to start/resume playback.');
          }
        }
      };

      await attemptStartResumePlayback(0);
      queryClient.invalidateQueries({ queryKey: ['playback-state'] });
      window.setTimeout(() => {
        setIsChangingPlayback(false);
      }, 1000);
    },
    [deviceId]
  );
  if (result.isLoading) {
    return <>Loading devices...</>;
  }
  if (result.isError) {
    return <>Error loading devices: {result.error.message}</>;
  }
  if (!result.data) {
    return (
      <>
        <p>No devices found</p>
        <ReloadButton setIsChangingPlayback={setIsChangingPlayback} />
      </>
    );
  }

  if (!deviceId) {
    return (
      <>
        <p>No device id found</p>
        <ReloadButton setIsChangingPlayback={setIsChangingPlayback} />
      </>
    );
  }

  const hasPlayContext = playbackResult.data?.item?.name !== undefined;
  const playBackTitle = playbackResult.data?.item?.name || 'Nothing playing';

  return (
    <>
      <Group styles={{ margin: '8px' }}>Wiedergabe auf {device.name}</Group>
      {canControlAirplay && (
        <Group styles={{ margin: '8px' }}>
          <ChangeAirPlayDeviceButton
            target="kueche"
            icon={<IconFridge />}
            setTarget={setAirplayDevice}
            isChangingPlayback={isChangingPlayback}
          />
          <ChangeAirPlayDeviceButton
            target="aren"
            icon={<IconCar />}
            setTarget={setAirplayDevice}
            isChangingPlayback={isChangingPlayback}
          />
          <ChangeAirPlayDeviceButton
            target="wohnzimmer"
            icon={<IconSofa />}
            setTarget={setAirplayDevice}
            isChangingPlayback={isChangingPlayback}
          />
        </Group>
      )}

      {children}
      <Group>
        <ReloadButton setIsChangingPlayback={setIsChangingPlayback} />
      </Group>
      {hasPlayContext && (
        <>
          <div style={{ height: '90px' }} />
          <div
            style={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              backgroundColor: 'white',
              width: '100%',
              padding: '8px 0px',
              borderTop: '1px solid #e0e0e0',
              transform: 'translate3d(0,0,0)',
            }}
          >
            <Group>
              {playbackResult.data?.is_playing ? (
                <Button
                  variant="solid"
                  size="md"
                  loading={isChangingPlayback}
                  onClick={async () => {
                    setIsChangingPlayback(true);
                    try {
                      await sdk.player.pausePlayback(deviceId);
                      queryClient.invalidateQueries({ queryKey: ['playback-state'] });
                      window.setTimeout(() => {
                        setIsChangingPlayback(false);
                      }, 1000);
                    } catch (e) {
                      console.log(e);
                      setIsChangingPlayback(false);
                    }
                  }}
                >
                  <IconPlayerPause />
                </Button>
              ) : (
                <Button
                  variant="solid"
                  size="md"
                  loading={isChangingPlayback}
                  onClick={async () => {
                    setIsChangingPlayback(true);
                    try {
                      await sdk.player.startResumePlayback(deviceId);
                      queryClient.invalidateQueries({ queryKey: ['playback-state'] });
                      window.setTimeout(() => {
                        setIsChangingPlayback(false);
                      }, 1000);
                    } catch (e) {
                      console.log(e);
                      setIsChangingPlayback(false);
                    }
                  }}
                >
                  <IconPlayerPlay />
                </Button>
              )}
            </Group>
            <div style={{ height: '8px' }} />
            <Group>{playBackTitle}</Group>
          </div>
        </>
      )}
    </>
  );
}
